<!--
 * @Author: Link
 * @Date: 2021-01-19 14:59:46
 * @LastEditTime: 2021-05-20 13:59:00
-->
<template>
  <div>
    <el-card class="editContainer">
      <el-card class="searchContainer">
        <el-form inline>
          <el-form-item label="账号名称">
            <el-input class="searchInput" v-model.trim="search.accountName" placeholder="账号名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="超级管理员">
            <el-input
              class="searchInput"
              v-model.trim="search.contactName"
              placeholder="超级管理员"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="search.applymentState">
              <el-option
                v-for="key in Object.keys(applymentState)"
                :key="key"
                :label="applymentState[key]"
                :value="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营类型">
            <el-select v-model="search.operationType">
              <el-option
                v-for="key in Object.keys(operationType)"
                :key="key"
                :label="operationType[key]"
                :value="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="resetSearch">重置</el-button>
            <el-button type="primary" @click="handleSearch"> 搜索</el-button>
            <el-button v-auth="'superAdmin'" size="mini" type="success" @click="handleOpenAdd">添加</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-table :data="table.list">
        <el-table-column v-for="col in Object.keys(table.cols)" :key="col" :label="table.cols[col]" :prop="col">
          <template slot-scope="{ row }">
            <template v-if="col === 'bank'">
              <div>银行：{{ row['bankName'] }}</div>
              <div>账号：{{ row['accountNumber'] }}</div>
            </template>
            <template v-else-if="col === 'operationType'">
              <div class="operationType" v-if="row[col] === 0" style="background:rgba(33,150,243,.1);color:#2196F3">
                直营
              </div>
              <div class="operationType" v-else style="background:rgba(76,175,80,.1);color:#4CAF50">加盟</div>
            </template>
            <template v-else-if="col === 'bankAccountType'">{{ row[col] === '74' ? '对公账户' : '对私账户' }}</template>
            <template v-else-if="col === 'applymentState'">
              <el-button v-if="row.applymentState == 4" size="mini" type="danger" plain>
                已驳回
              </el-button>
              <el-button v-else-if="row.applymentState == 6" size="mini" type="success" plain>
                完成
              </el-button>
              <el-button
                v-else-if="[2, 5].includes(row.applymentState)"
                size="mini"
                type="success"
                plain
                @click="handleShowWxCode(row)"
              >
                <span v-if="row.applymentState === 5">待签约</span>
                <span v-else>待账户验证</span>
              </el-button>
              <el-button v-else size="mini" type="primary" plain>{{ applymentState[row[col]] }}</el-button>
            </template>
            <template v-else>{{ row[col] }}</template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="{ row }">
            <el-button @click="handleView(row)" type="primary" plain size="mini">查看</el-button>
            <el-button v-auth="'superAdmin'" @click="handleEdit(row)" type="warning" plain size="mini">编辑</el-button>
            <el-button
              v-auth="'superAdmin'"
              v-if="row.applymentState == 6"
              @click="handleAssets(row)"
              type="warning"
              plain
              size="mini"
              >资产</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialog.show"
        @close="dialog.show = false"
        :title="dialog.type === 'view' ? '查看' : dialog.type === 'add' ? '添加' : '编辑'"
      >
        <addDialog :id="dialog.id" :show="dialog.show" :type="dialog.type" @success="handleSearch" />
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="wxCodeData.isShow"
        :title="showTransfer ? '待验证账号(请在下面两种验证方式中选择其中一种验证)' : '微信验证'"
      >
        <div class="wxCodeDataContainer">
          <h3 style="margin: 20px">微信验证</h3>
          <div v-if="tip">暂无微信二维码</div>
          <canvas ref="canvas"></canvas>
          <div v-if="wxCodeData.applymentState === 5">请使用商户号绑定的管理员微信扫码</div>
          <div v-else>请使用法人绑定的微信扫码</div>
        </div>

        <div class="accountValidation" v-if="showTransfer">
          <h3 style="margin: 20px;text-align:center">汇款验证</h3>
          <div class="item" v-for="(item, index) in Object.keys(accountValidation.label)" :key="index">
            <div class="des">{{ accountValidation.label[item] }}</div>
            <div>{{ accountValidation.value[item] }}</div>
          </div>

          <div style="color:red;font-size: 18px;text-align:center">
            请商家汇指定金额到指定银行账号，进行入驻账户验证
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { pagination } from '@/mixin/pagination'
import addDialog from './__com__/addDialog'
export default {
  components: { addDialog },
  mixins: [pagination],
  data() {
    return {
      tip: false,
      showTransfer: false,
      accountValidation: {
        label: {
          accountName: '汇款账号名',
          deadline: '有效时间至',
          destinationAccountBank: '收款账号开户行所在地',
          destinationAccountName: '收款账号开户名',
          destinationAccountNumber: '收款银行账号',
          payAmount: '汇款金额(单位：分)'
        },
        value: {}
      },
      confirmDialog: false,
      id: new Date().toString(),
      search: {
        accountName: '',
        contactName: '',
        applymentState: '',
        operationType: ''
      },
      operationType: {
        '-1': '全部',
        0: '直营',
        1: '加盟'
      },
      applymentState: {
        1: '资料校验中',
        2: '待账户验证',
        3: '审核中',
        4: '已驳回',
        5: '待签约',
        6: '完成',
        7: '已冻结'
      },
      table: {
        cols: {
          accountName: '账号名称',
          outRequestNo: '业务申请编号',
          operationType: '运营类型',
          areaCount: '绑定地区数',
          bank: '银行账号信息',
          // contactName: '超级管理员',
          bankAccountType: '类型',
          applymentState: '审核状态'
        },
        list: []
      },
      dialog: {
        id: 0,
        type: 'view', //view:查看，edit:编辑,add:添加
        show: false
      },
      assetsDialog: {
        id: 0,
        show: false,
        data: { can: false, name: '34343', account: 343424323, bankName: '大事件', number: 234234524 }
      },
      wxCodeData: {
        isShow: false,
        url: '',
        applymentState: 0
      },
      loading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    resetSearch() {
      this.search = {
        accountName: '',
        contactName: '',
        applymentState: '',
        operationType: ''
      }
      this.getList()
    },
    handleSearch() {
      this.currentPage
      this.dialog.show = false
      this.getList()
    },
    getList() {
      this.loading = true
      let params = this.search
      params.page = this.currentPage
      params.size = this.pageSize
      if (params.operationType === '-1') {
        params.operationType = ''
      }
      let str = ''
      Object.keys(params).map(key => {
        str += `${key}=${params[key]}&`
      })
      this.$http
        .get('boom-center-wechat-service/sysAdmin/platform/sub-merchant/page?' + str)
        .then(res => {
          console.log(res)
          this.total = parseInt(res.total)
          this.table.list = res.list
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => (this.loading = false))
    },
    handleOpenAdd() {
      this.dialog.id = 0
      this.dialog.show = true
      this.dialog.type = 'add'
    },
    handleAssets(row) {
      console.log(row.subMchid)
      this.$router.push({
        path: '/setting/platform/twoLevelMerchant/withdraw',
        query: {
          id: row.subMchid,
          name: row.accountName,
          subMerchantId: row.platformSubMerchantId
        }
      })
    },
    handleView(row) {
      this.dialog.id = row.platformSubMerchantId
      this.dialog.show = true
      this.dialog.type = 'view'
    },
    handleEdit(row) {
      this.dialog.id = row.platformSubMerchantId
      this.dialog.show = true
      this.dialog.type = 'edit'
    },
    handleShowWxCode(row) {
      this.showTransfer = false
      this.$http
        .get(
          `boom-center-wechat-service/sysAdmin/platform/sub-merchant/out-request-no?outRequestNo=${row.outRequestNo}`
        )
        .then(res => {
          if (res.accountValidation) {
            this.showTransfer = true
            this.accountValidation.value = res.accountValidation
          }

          console.log(this.accountValidation.value)
          this.wxCodeData.isShow = true
          this.wxCodeData.applymentState = row.applymentState
          this.tip = false
          if (!res.signUrl && !res.legalValidationUrl) {
            this.tip = true
            this.$nextTick(() => {
              this.$refs.canvas.height = this.$refs.canvas.height // 重置画布
            })
            return
          }

          this.$nextTick(() => {
            const canvas = this.$refs.canvas
            QRCode.toCanvas(canvas, res.signUrl || res.legalValidationUrl, function(error) {
              if (error) console.error(error)
              console.log('success!')
            })
          })
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.operationType {
  width: fit-content;
  padding: 0 8px;
  border-radius: 5px;
}
.wxCodeDataContainer {
  text-align: center;
}
.pagination {
  margin-top: 10px;
  text-align: center;
}
// .assets {
//   .back,
//   .account,
//   .backName {
//     margin-left: 20px;
//     font-size: 18px;

//     span {
//       font-size: 16px;
//       font-weight: 100;
//     }
//   }
//   .allMoney {
//     margin-top: 20px;
//     font-size: 18px;
//     text-align: center;
//     font-weight: bold;
//     .number {
//       font-size: 26px;
//       font-weight: bold;
//       color: red;
//     }
//   }
//   .alreadyWithdraw {
//     margin: 6px 0 26px;
//     text-align: center;
//     font-size: 12px;
//     color: grey;
//   }
//   .freezeMoneyAndcanWithdraw {
//     margin: 0 40px;
//     display: flex;
//     justify-content: space-around;
//   }
//   .tip {
//     color: red;
//     margin-top: 20px;
//     font-size: 12px;
//   }
// }
</style>
